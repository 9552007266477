import type { FooterItem } from './types';

export const siteMetadata = {
	title: 'Akash Rajpurohit',
	author: 'Akash Rajpurohit',
	headerTitle: 'Akash Rajpurohit',
	description: `Hi!, I'm Akash Rajpurohit, a self taught software developer based in India. I specialize in developing web applications and cross platform mobile applications using modern technologies.`,
	language: 'en-us',
	theme: 'dark', // system, dark or light
	siteUrl: 'https://akashrajpurohit.com',
	siteRepo: 'https://github.com/AkashRajpurohit/akashrajpurohit.com',
	siteLogo: 'static/images/logo-v3.png',
	socialBanner: 'static/images/twitter-card-v3.png',
	image: '/static/images/avatar.png',
	image_small: '/static/images/avatar_small.jpg',
	image_large_jpg: '/static/images/avatar-v2.jpg',
	image_large_webp: '/static/images/avatar-v2.webp',
	image_without_bg_png: '/static/images/avatar_without_bg.png',
	image_without_bg_webp: '/static/images/avatar_without_bg.webp',
	favicon_32: '/static/favicons/favicon-32x32.png',
	favicon_16: '/static/favicons/favicon-16x16.png',
	email: 'me@akashrajpurohit.com',
	github: 'https://github.com/AkashRajpurohit',
	twitter: 'https://twitter.com/AkashWhoCodes',
	linkedin: 'https://www.linkedin.com/in/AkashRajpurohit',
	instagram: 'https://www.instagram.com/akashwho.codes',
	reddit: 'https://www.reddit.com/user/Developer_Akash',
	mastodon: 'https://mastodon.social/@akashrajpurohit',
	youtube: 'https://www.youtube.com/TheITGuy',
	donate: 'https://ko-fi.com/akashrajpurohit',
	rss: '/rss.xml',
	resumeDownloadLink: '/static/pdfs/AkashRajpurohit_Resume.pdf',
	enableResumeDownload: false,
	locale: 'en-US',
	pages: {
		home: '/',
		blogs: '/blogs/',
		snippets: '/snippets/',
		about: '/about/',
		projects: '/projects/',
		uses: '/uses/',
		resume: '/resume/',
		newsletter: '/newsletter/',
		tweets: '/tweets/',
		tags: '/tag/',
		contact: '/contact/',
		hireMe: '/hire-me/',
		gladIBecameAProgrammer: '/glad-i-became-a-programmer/',
		privacyPolicy: '/privacy-policy/',
		sponsors: '/sponsors/',
		support: '/support/',
	},
	flags: {
		includePublicationMediaMetaData: true,
	},
	ad: {
		carbonAds: {
			code: 'CEAIC23N',
			placement: 'akashrajpurohitcom',
			enabled: true,
			enabledInDev: false,
		},
		ethicalAds: {
			publisher: 'akashrajpurohitcom',
			enabled: true,
			enabledInDev: false,
		},
	},
	analytics: {
		umamiHost: import.meta.env.PUBLIC_UMAMI_HOST,
		umamiWebsiteId: import.meta.env.PUBLIC_UMAMI_WEBSITE_ID,
		umamiScriptUrl: import.meta.env.PUBLIC_UMAMI_SCRIPT_URL,
	},
	devMode: {
		showDraftPosts: true,
		showDraftNewsletters: true,
		showSponsoredPosts: true,
		cf: {
			turnstileSiteKey: '1x00000000000000000000BB',
			turnstileSecretKey: '1x0000000000000000000000000000000AA',
		},
	},
	cf: {
		turnstileSiteKey: '0x4AAAAAAANUKFXOUPwouQG0',
	},
};

export const headerNavLinks = [
	{ href: siteMetadata.pages.about, title: 'About' },
	{ href: siteMetadata.pages.projects, title: 'Projects' },
	{ href: siteMetadata.pages.blogs, title: 'Blogs' },
	{ href: siteMetadata.pages.sponsors, title: 'Sponsors' },
	{ href: siteMetadata.pages.newsletter, title: 'Newsletter' },
];

export const footerLinks: Record<string, FooterItem[]> = {
	general: [
		{ name: 'Home', href: siteMetadata.pages.home },
		{ name: 'About', href: siteMetadata.pages.about },
		{ name: 'Blogs', href: siteMetadata.pages.blogs },
		{ name: 'Projects', href: siteMetadata.pages.projects },
		{ name: 'Snippets', href: siteMetadata.pages.snippets },
	],
	specifics: [
		{ name: 'Support', href: siteMetadata.pages.support },
		{ name: 'Sponsors', href: siteMetadata.pages.sponsors },
		{ name: 'My Journey', href: siteMetadata.pages.gladIBecameAProgrammer },
		// { name: 'Hire me', href: siteMetadata.pages.hireMe },
		{ name: 'Resume', href: siteMetadata.pages.resume },
		{ name: 'Newsletter', href: siteMetadata.pages.newsletter },
	],
	extra: [
		{ name: 'Uses', href: siteMetadata.pages.uses },
		{ name: 'Tweets', href: siteMetadata.pages.tweets },
		{ name: 'Contact', href: siteMetadata.pages.contact },
		{ name: 'Tags', href: siteMetadata.pages.tags },
		{ name: 'Privacy Policy', href: siteMetadata.pages.privacyPolicy },
	],
};

export const pageWiseMeta = {
	home: {
		title: 'Home',
		description: siteMetadata.description,
	},
	about: {
		title: 'About',
		description: 'Details of my life and my journey as software engineer',
	},
	blogs: {
		title: 'Blogs',
		description: (totalPosts: number) =>
			`I have been writing since 2020, mostly around tech and my career. In total I have written ${totalPosts} posts. I hope you enjoy them.`,
	},
	projects: {
		title: 'Projects',
		description:
			'Things I build when I am learning something new or just for fun.',
	},
	snippets: {
		title: 'Snippets',
		description: 'Simple code snippets that I use in my projects.',
	},
	uses: {
		title: 'Uses',
		description: 'The products and types of equipment I use on daily basis.',
	},
	resume: {
		title: 'Resume',
		description: 'Check out how my professional journey have been like so far.',
	},
	tweets: {
		title: 'Tweets',
		description: 'Tweets that I loved reading and saved here as bookmarks.',
	},
	newsletter: {
		title: 'Newsletter',
		description:
			'The place where you can subscribe to my newsletter to receive early updates.',
	},
	privacyPolicy: {
		title: 'Privacy Policy',
		description: 'Privacy policy of akashrajpurohit.com',
	},
	tag: {
		title: 'Tags',
		description: 'Filter blogs via specific tags',
	},
	hireMe: {
		title: 'Hire me',
		description:
			'I offer help with software design and architecture, TDD, code reviews, full stack development and development processes.',
	},
	contact: {
		title: 'Contact',
		description: 'Get in touch with me quickly via the easy to use form.',
	},
	gladIBecameAProgrammer: {
		title: 'Glad I Became A Programmer',
		description:
			'Collection of my stories when I was glad that I became a programmer.',
	},
	sponsors: {
		title: 'Sponsors',
		description: 'Sponsor or advertise on akashrajpurohit.com',
	},
	support: {
		title: 'Support',
		description: 'Support akashrajpurohit.com',
	},
	notFound: {
		title: 'Page Not Found',
		description: 'This page does not exist',
	},
};
